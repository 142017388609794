<template>
    <Page>
        <Content class="page-content">
            <Card class="content-wrapper">
                <div class="buttons-container">
                    <router-link :to="{ name: 'LancementEtablissement' }">
                        <Button title="Établissements" type="primary"
                            :active="['LancementEtablissement', 'MonitoringEstablishmentsCharts'].includes($route.name)" />
                    </router-link>
                    <router-link :to="{ name: 'LancementReeducator' }">
                        <Button title="Réeducateurs" type="primary"
                            :active="['LancementReeducator'].includes($route.name)" />
                    </router-link>
                    <router-link :to="{ name: 'LancementPatient' }">
                        <Button title="Patients" type="primary"
                            :active="['LancementPatient'].includes($route.name)" />
                    </router-link>
                </div>
                <router-view></router-view>
            </Card>
        </Content>
    </Page>
</template>
  
<script>
import Page from '@/components/Layout/Page'
import Content from '@/components/Layout/Content'
import Card from '@/components/Layout/Card'
import Button from '@/components/Button'

export default {
    name: 'LancementHome',
    components: {
        Page, Content, Card, Button
    }
}
</script>
  
<style lang="scss" scoped>
.page-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .content-wrapper {
        position: relative;
        flex-grow: 1;
        overflow-y: scroll;
        height: calc(100vh - 100px - 1rem - 40px);

        .buttons-container {
            display: flex;

            a {
                margin: 0 6px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
  